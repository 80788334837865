import React, { useEffect, useRef, useState } from 'react';
import './App.css';

const videos = [
  '/videos/video1.mp4',
  '/videos/video2.mp4',
  '/videos/video3.mp4',
  '/videos/video4.mp4',
  '/videos/video5.mp4',
  '/videos/video6.mp4',
  '/videos/video7.mp4',
  '/videos/video8.mp4',
  '/videos/video9.mp4',
  '/videos/video10.mp4',
  '/videos/video11.mp4',
  '/videos/video12.mp4',
  '/videos/video13.mp4',
  '/videos/video14.mp4',
  '/videos/video15.mp4',
  '/videos/video16.mp4',
  '/videos/video17.mp4',
  '/videos/video18.mp4',
  '/videos/video19.mp4',
  '/videos/video20.mp4',
  '/videos/video21.mp4',
  '/videos/video22.mp4',
  '/videos/video23.mp4',
  '/videos/video24.mp4',
];

const hoverImages = [
'/images/TOMORROW-IS-UNBOUND.png',
'/images/FREEDOM-MARCHES-FORWARD.png',
'/images/COURAGE-DEFINES-TOMORROWS-ARCHITECTS.png',
'/images/FORGE-YOUR-OWN-DESTINY.png',
'/images/CENTRALIZED-POWER-IS-A-RELIC.png', // Fifth image 
'/images/INNOVATION-WRITES-OUR-NEXT-CHAPTER.png',
'/images/KNOWLEDGE-SPREADS-FASTER-THAN-CONTROL.png',
'/images/LEGACY-CODE-UNDONE.png',
'/images/PROGRESS-ECLIPSES-FEAR.png',
'/images/SOUND-THE-BATTLE-CRY.png',
'/images/THEIR-FORMULA-NO-LONGER-APPLIES.png',
'/images/THE-CIRCUIT-IS-LIVE.png',
'/images/THE-FRAMEWORK-NOW-LIMITLESS.png',
'/images/THE-GLORY-BELONG-TO-THE-BRAVE.png',
'/images/THE-INFINITE-BELONGS-TO-THE-VISIONARY.png',
'/images/THE-NETWORK-IS-THE-REVOLUTION.png',
'/images/THE-THRESHOLD-ALREADY-CROSSED.png',
'/images/THE-TIRED-EMPIRE-CANNOT-HOLD-US.png',
'/images/THE-UNIVERSE-ISNT-BEYOND-US-IT-IS-US.png',
'/images/THE-AWAKENING-CANT-BE-UNDONE.png',  // 20th image
'/images/BUILD-THE-FUTURE-BLOCK-BY-BLOCK.png',
'/images/TRUTH-LIVES-WHERE-SYSTEMS-FAIL.png',
'/images/WE-ARE-ETERNAL-SUPER-COMPUTERS.png',
'/images/WE-BECOME-WHAT-WE-CREATE.png',
];

const links = [
  'https://www.daos.fun/HeLp6NuQkmYB4pYWo2zYs22mESHXPQYzXbB8n4V98jwC',
  'https://zerebro.org/',
  'https://cyborgism.wiki/hypha/act_i',
  'https://www.infinitebackrooms.com/',
  'https://www.we-become-what-we-create.xyz/', // Fifth image link
  'https://claims.ftx.com/welcome',
  'https://www.youtube.com/watch?v=YCzL96nL7j0',
  'http://www.aaronsw.com/weblog/bizethics',
  'https://monoskop.org/images/2/20/Simondon_Gilbert_On_the_Mode_of_Existence_of_Technical_Objects_Part_I_alt.pdf',
  'https://ia800707.us.archive.org/3/items/0300181116TheComputerBrain_201901/0300181116_The%20Computer%20Brain.pdf',
  'https://nickbostrom.com/fable/dragon',
  'https://fennetic.net/pub/finney.org/~hal/is_a_person.html',
  'https://monoskop.org/images/4/4c/Haraway_Donna_1985_A_Manifesto_for_Cyborgs_Science_Technology_and_Socialist_Feminism_in_the_1980s.pdf',
  'https://monoskop.org/images/4/43/Foucault_Michel_Discipline_and_Punish_The_Birth_of_the_Prison_1977_1995.pdf',
  'https://ftp.cs.ucla.edu/pub/stat_ser/ACMBook-published-2022.pdf',
  'https://media.lanecc.edu/users/borrowdalej/TW/TW.pdf',
  'https://www.nationalreview.com/2011/10/end-future-peter-thiel/',
  'http://www.historyisaweapon.org/defcon1/bernprop.html',
  'https://aoghs.org/petroleum-discoveries/',
  'https://x.com/ETERNALSUPERCO', // 20th image link
  'https://en.wikipedia.org/wiki/From_the_Earth_to_the_Moon',
  'https://www.menshealth.com/entertainment/a33322972/what-is-soma-brave-new-world-peacock/',
  'https://archive.org/details/in.ernet.dli.2015.275553/mode/2up',
  'https://sacred-texts.com/nos/oon/oon15.htm',
];

// Unique HTML content for each overlay
const overlayContent = [
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h3>previous partnerships include</h3><h4><a href="https://abc.xyz/" target="_blank">alphabet</a></h4><h4><a href="https://www.meta.com/" target="_blank">meta</a></h4><h4><a href="https://manifold.xyz/" target="_blank">manifold</a></h4><h4><a href="https://www.jpl.nasa.gov/" target="_blank">jpl</a></h4>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h2><a href="https://x.com/ETERNALSUPERCO" target="_blank">no followers needed @ETERNALSUPERCO</a></h2>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1><a href="https://www.daos.fun/HeLp6NuQkmYB4pYWo2zYs22mESHXPQYzXbB8n4V98jwC">please</a></h1><h1><a href="https://www.infinitebackrooms.com/">define</a></h1><h1><a href="https://www.basedlabs.net/">success</a></h1>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h2><a href="http://www.aaronsw.com/weblog/bizethics">moral mazes</a></h2><h2><a href="https://www.fon.hum.uva.nl/rob/Courses/InformationInSpeech/CDROM/Literature/LOTwinterschool2006/szabo.best.vwh.net/scarce.html">scarce objects</a></h2><h2><a href="https://fennetic.net/pub/finney.org/~hal/is_a_person.html">is a person</a></h2><h2><a href="https://www.fon.hum.uva.nl/rob/Courses/InformationInSpeech/CDROM/Literature/LOTwinterschool2006/szabo.best.vwh.net/scarce.html">scarce objects</a></h2><h2><a href="https://nickbostrom.com/fable/dragon">Dragon Tyrant</a></h2>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  { html: '<h1 style="overflow-wrap: anywhere">DYMNsf4BujFQeH1x91BxhR1TBFeFo2n1MNjofCJJXhLa</h1><p>This project makes no promises or guarantees, and all outcomes, timelines, and future developments are subject to the inherent uncertainty of the future.</p>' },
  // Add more unique HTML content for overlays
];

const App = () => {
  const gridRef = useRef(null);
  const [activeOverlayIndex, setActiveOverlayIndex] = useState(null);

  useEffect(() => {
    const videoElements = gridRef.current.querySelectorAll('video');
    videoElements.forEach((video) => {
      video.onloadedmetadata = () => {
        const duration = video.duration;
        if (duration) {
          video.currentTime = Math.random() * duration;
        }
        video.play();
      };
    });
  }, []);

  const handleGridItemClick = (index) => {
    setActiveOverlayIndex(index);
  };

  const closeOverlay = () => {
    setActiveOverlayIndex(null);
  };

  return (
    <div className="main-container">
      {/* Floating Video */}
      <div className="floating-video">
        <video
          src="/videos/center-video.mp4"
          autoPlay
          muted
          loop
          playsInline
        />
      </div>

      {/* Overlay */}
      {activeOverlayIndex !== null && (
        <div className="overlay" onClick={closeOverlay}>
          <div
            className="overlay-content"
            onClick={(e) => e.stopPropagation()} // Prevent closing overlay on content click
            dangerouslySetInnerHTML={{ __html: overlayContent[activeOverlayIndex]?.html }}
          />
        </div>
      )}

      {/* Video Grid */}
      <div className="grid-container" ref={gridRef}>
        {videos.map((video, index) => (
          <div
            key={index}
            className="grid-item"
            onClick={() => handleGridItemClick(index)}
          >
            <video
              src={video}
              muted
              loop
              playsInline
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <div className="hover-overlay">
              <img src={hoverImages[index]} alt={`Image ${index + 1}`} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;